import _ from "lodash";
import styled from "styled-components";
import {
  Card,
  Row,
  Col,
  Image,
  Button,
  Form,
  FormControl,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import LoadingData from "./LoadingData";
import axios from "axios";
import Footer from "./Footer";

const StyledInput = styled(FormControl)`
  border-radius: 10px;
`;

const StyledCard = styled(Card)`
  background-color: #ffffff;
  border-radius: 12px;
  border-color: transparent;
  height: 80px;
  justify-content: center;
  margin-top: 10px;
  padding-top: 4px;
`;

const StyledCallBtn = styled(Button)`
  background-color: #f84f88;
  border-color: transparent;
`;

const StyledAvatar = styled(Image)`
  height: 60px;
  margin-left: 10px;
`;

const NameText = styled.h5`
  font-size: 14px;
  margin-bottom: 2px;
`;

const TotalText = styled.p`
  font-size: 16px;
  color: #f84f88;
  margin-bottom: 10px;
`;

const onCall = "tel:";

function SignInCard() {
  const [data, setData] = useState([]);
  const [defaultData, setDefaultData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  const [input] = useState("");
  const [uploadData, setUploadData] = useState([]);

  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const updateInput = async (input) => {
    const e = input.target.value;
    const filtered = defaultData.filter((data) => {
      return (
        data.FullName.toLowerCase().includes(e.toLowerCase()) ||
        data.NickName.toLowerCase().includes(e.toLowerCase()) ||
        data.DeptName.toLowerCase().includes(e.toLowerCase())
      );
    });
    if (e.length === 0) {
      setIsSearch(false);
    }
    setData(filtered);
    setIsSearch(true);
  };

  // GET ATKUPLOAD DATA FROM STRAPI
  const getAtkUploadData = () => {
    // const date = new Date().toISOString().substr(0, 7);
    // const spliteDate = date.split("-");
    let today = new Date(Date.now());
    let thisYear = today.getFullYear();
    let thisMonth = today.getMonth() + 1;
    let thisDay = today.getDate();
    let rmupload = 0
    let rdupload = 0
    let lastDayofMonth = new Date(thisYear, thisMonth, 0).getDate();//Get Last Day of Month
    const currentdate = new Date();
    currentdate.setDate(currentdate.getDate()); //วันที่ปัจจุบัน
    var oneJan = new Date(currentdate.getFullYear(),0,1); //หาวันแรกของปี
    var numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000)); //หาวันที่จากทั้งปี
    var result = Math.ceil((numberOfDays) / 7); //หาจำนวนสัปดาห์ของปีนั้น

    if(thisDay==lastDayofMonth)//เช็ควันสินเดือน
      {
        rmupload = thisMonth + 1
        rdupload = 1
      }
    else if(thisDay < 15)//ต้นเดือนยังไม่เลยกลางเดือน กำหนดให้เป็นรอบของวันที่ 1 ในเดือนนั้น
      {
        rmupload = thisMonth
        rdupload = 1
      }
    else//เลยวันที่ 14 กำหนดให้เป็นรอบของวันที่ 16 ในเดือนนั้น
      {
        rmupload = thisMonth
        rdupload = 16
      }
    // if(thisDay==lastDayofMonth)//วันสินเดือน
    // {
    //   thisDay = lastDayofMonth
    // }
    // else if(thisDay < 15)//ต้นเดือนยังไม่เลยกลางเดือน ให้เช็คณวันสิ้นเดือนที่ผ่านมา
    // {
    //   thisMonth = thisMonth - 1
    //   thisDay = new Date(thisYear, thisMonth, 0).getDate()
    // }
    // else//เลยวันที่ 15 ให้เช็ควันที่ 15 ของเดือนนั้น
    // {
    //   thisDay = 15
    // }

    axios
    .get(
      'https://internal-api.fti.or.th/fti-atk-uploader-2022-s?uploadYear=' +
        thisYear +
        '&WeekNumber=' +
        result +
        "&_limit=5000"
        // '&roundMonth=' +
        // rmupload +
        // '&roundDay=' +
        // rdupload +
        // "&_limit=5000"
    )
        .then((res) => {
          setUploadData(res.data);
        });
    // if (thisDay <= 15) {
    //   axios
    //     .get(
    //       "https://internal-api.fti.or.th/fti-atk-uploader-2022-s?uploadYear=" +
    //         thisYear +
    //         "&uploadMonth=" +
    //         thisMonth +
    //         "&uploadDay_lte=15" +
    //         "&_limit=5000"
    //     )
    //     .then((res) => {
    //       setUploadData(res.data);
    //     });
    // } else {
      // axios
      //   .get(
      //     "https://internal-api.fti.or.th/fti-atk-uploader-2022-s?uploadYear=" +
      //       thisYear +
      //       "&uploadMonth=" +
      //       thisMonth +
      //       "&uploadDay_gte=16" +
      //       "&_limit=5000"
      //   )
      //   .then((res) => {
      //     setUploadData(res.data);
      //   });
    // }
  };

  useEffect(() => {
    const data = setInterval(() => {
      axios
        .get("https://line-connect.fti.or.th/getEmpSignIn")
        .then((response) => {
          const sorts = response.data;
          const dataSortByTime = sorts.sort(function (a, b) {
            let c = new Date(a.TimeScan);
            let d = new Date(b.TimeScan);
            return d - c;
          });
          getAtkUploadData(); // GET ATKUPLOAD DATA FROM STRAPI
          setIsLoading(false);
          if (!isSearch) {
            setDefaultData(dataSortByTime);
            setData(dataSortByTime);
          }
        });
    }, 3500);
    return () => clearInterval(data);
  });

  const filterIsUpload = (id) => {
    return _.some(uploadData, { EmpID: id });
  };

  return (
    <>
      {isLoading ? (
        <LoadingData />
      ) : (
        <TotalText> ทั้งหมด {data.length} คน</TotalText>
      )}

      {data.map((data) =>
        data ? (
          <div key={data.EmployeeID}>
            <StyledCard className="shadow-sm animate__animated animate__fadeInUp">
              <Row>
                <Col xs={2} className="mx-md-auto mt-1">
                  <StyledAvatar src={data.ImageUrl} />
                </Col>
                <Col xs={7} className="mt-2">
                  <span>
                    <NameText>
                      {data.FullName} ({data.NickName})
                    </NameText>
                  </span>

                  <p className="small mb-0">
                    ฝ่าย {data.DeptName}. - เวลาเข้า{" "}
                    {data.TimeScan.replace("1970-01-01T", "").replace(
                      ".000Z",
                      ""
                    )}{" "}
                    น.
                  </p>

                  {/* // ATK RESULT DISPLAY */}

                  {filterIsUpload(data.EmployeeID) ? (
                    <p className="small text-success">
                      <span class="badge badge-pill badge-success">
                        ATK{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          fill="currentColor"
                          class="bi bi-check-circle ml-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                        </svg>
                      </span>
                    </p>
                  ) : (
                    <p className="small text-danger">
                      <span class="badge badge-pill badge-danger">
                        ATK
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          fill="currentColor"
                          class="bi bi-x-circle ml-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      </span>
                    </p>
                  )}

                  {/* // ATK RESULT DISPLAY */}
                </Col>
                <Col xs={2} className="text-center mt-3">
                  <StyledCallBtn
                    className="rounded-circle"
                    href={onCall + data.MobilePhone}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-telephone-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                      />
                    </svg>
                  </StyledCallBtn>
                </Col>
              </Row>
            </StyledCard>
          </div>
        ) : null
      )}

      <Footer>
        <Form className="d-flex">
          <StyledInput
            type="search"
            placeholder="ค้นหา"
            className="mr-2"
            aria-label="Search"
            input={input}
            onChange={updateInput}
            onKeyPress={onEnterPress}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            fill="currentColor"
            className="bi bi-search mt-1"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
        </Form>
      </Footer>
    </>
  );
}

export default SignInCard;
