import styled from "styled-components";

const DateTextStyled = styled.h4`
  text-decoration: underline;
  text-decoration-color: #f10a58;
  text-decoration-thickness: 0.1em;
  text-decoration-style: solid;
  font-size: 18px;
  margin-bottom: 0px;
`;

function DateTitle() {
  const date = new Date(Date.now());
  const result = date.toLocaleDateString("th-TH", {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
  });

  return (
    <>
      <DateTextStyled>{result}</DateTextStyled>
    </>
  );
}

export default DateTitle;
