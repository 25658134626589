import { Navbar, Container } from "react-bootstrap";
import styled from "styled-components";

const FootNavStyled = styled(Navbar)`
  padding-bottom: 20px;
  height: 10vh;
  margin-top: auto;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
`;

function Footer(props) {
  return (
    <>
      <FootNavStyled
        fixed="bottom"
        expand="lg"
        variant="light"
        bg="light"
        className="shadow-xl"
      >
        <div className="mx-auto">
          <Container>{props.children}</Container>
        </div>
      </FootNavStyled>
    </>
  );
}

export default Footer;
