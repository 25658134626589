import { Container } from "react-bootstrap";
import styled from "styled-components";

const LoadingLogo = styled.img`
  margin-top: 100px;
  height: 150px;
`;

const LoadingText = styled.h4`
  color: #f84f88;
  font-size: 18px;
  margin-top: 5px;
`;

function LoadingData() {
  return (
    <>
      <Container>
        <div className="text-center">
          <LoadingLogo src={window.location.origin + "/NUKSUEB_ICON.png"} />
          <LoadingText className="animate__animated animate__flash animate__infinite">
            ขอไปสืบก่อน รอแปบนึงนะ
          </LoadingText>
        </div>
      </Container>
    </>
  );
}

export default LoadingData;
