import { Container } from "react-bootstrap";
import styled from "styled-components";
import Header from "../Header";

const StyledWrapper = styled.div`
  background-color: #e9e9e9;
  min-height: 100vh;
  padding-bottom: 30px;
`;

function Wrapper(props) {
  return (
    <>
      <StyledWrapper>
        <Header />
        <Container className="pt-3">{props.children}</Container>
      </StyledWrapper>
    </>
  );
}

export default Wrapper;
