import styled from 'styled-components';

const StyledTitle = styled.h4`
  color: #f84f88;
`;

function TitleText(props) {
  return (
    <>
      <StyledTitle>{props.message}</StyledTitle>
    </>
  );
}

export default TitleText;
