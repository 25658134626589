import styled from 'styled-components';

import { useContext } from 'react';

import { AuthContext } from '../hooks/AuthContext';
import { Navbar, Image } from 'react-bootstrap';

const StyledNavBar = styled(Navbar)`
  background-color: #f84f88;
  background-image: linear-gradient(15deg, #f84f88 25%, #ffa8cc 95%);
  height: 80px;
`;

const LogoImage = styled(Image)`
  height: 80px;
`;

function Header() {
  const { profile } = useContext(AuthContext);
  return (
    <>
      <StyledNavBar>
        <Navbar.Brand href="#home" className="text-light">
          น้องบอทยอดนักสืบ
          <p className="small text-light mb-0">{profile.displayName}</p>
        </Navbar.Brand>

        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <LogoImage src={window.location.origin + '/NUKSUEB_ICON.png'} />
          </Navbar.Text>
        </Navbar.Collapse>
      </StyledNavBar>
    </>
  );
}

export default Header;
