import { useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "./hooks/AuthContext";

import LoadingBar from "./components/Loadingbar";

import Wrapper from "./components/layouts/Wrapper";
import DateTitle from "./components/DateTitle";
import SignInCard from "./components/SignInCard";
import TitleText from "./components/TitleText";

import "./font.css";

function App() {
  const [profile, setProfile] = useState({});
  const [isAuth, setIsAuth] = useState(false);

  async function fetchUserData(profile) {
    const response = await axios.post(
      "https://line-connect.fti.or.th/auth/line",
      { userId: profile.userId }
    );
    if (response.data) {
      setIsAuth(true);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const liff = (await import("@line/liff")).default;
    await liff.init({ liffId: "1653821954-vN1J14xX" }); // PROD LIFF ID
    //await liff.init({ liffId: "1653821954-EpBQByOG" }); // DEV_LIFF ID
    if (liff.isLoggedIn()) {
      const profile = await liff.getProfile();
      setProfile(profile);
      fetchUserData(profile);
    } else {
      liff.login();
    }
  }, [profile.userId]);

  return (
    <>
      {isAuth ? (
        <AuthContext.Provider value={{ profile, setProfile }}>
          <Wrapper>
            <TitleText message="วันนี้ใครมาทำงานบ้างนะ ?" />
            <DateTitle />
            <div className="mt-3 mb-4 pb-4">
              <SignInCard />
            </div>
          </Wrapper>
        </AuthContext.Provider>
      ) : (
        <>
          <LoadingBar />
        </>
      )}
    </>
  );
}

export default App;
