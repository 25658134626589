import { Container } from "react-bootstrap";
import styled from "styled-components";

const LoadingLogo = styled.img`
  margin-top: 100px;
  height: 100px;
`;

function LoadingBar() {
  return (
    <>
      <Container>
        <div className="text-center">
          <LoadingLogo
            src={window.location.origin + "/NUKSUEB_ICON.png"}
            className="animate__animated animate__flip animate__infinite"
          />
        </div>
      </Container>
    </>
  );
}

export default LoadingBar;
